import { Component, OnInit } from '@angular/core';
import {DataService} from '../../services/data/data.service';
import {GalleryContainerComponent} from '../../shared/gallery-container/gallery-container.component';
import {MatDialog} from '@angular/material';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {
  public gallery: any = {};

  constructor(public data: DataService, private dialog: MatDialog) { }

  ngOnInit() {
    this.data.getGallery().subscribe(gallery => this.gallery = gallery[0]);
  }

  openGallery(imageIndex) {
    const data = {images: this.gallery.images, imageIndex};
    this.dialog.open(GalleryContainerComponent, {data, width: '100%', height: '100%', panelClass: 'dialog-transparent'});
  }
}
