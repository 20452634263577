import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { AppService } from '../../services/app/app.service';
import {ConfirmDialogComponent} from '../../shared/confirm-dialog/confirm-dialog.component';
import {MatDialog, MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {DataService} from '../../services/data/data.service';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-admin-blog',
  templateUrl: './admin-blog.component.html',
  styleUrls: ['./admin-blog.component.css']
})
export class AdminBlogComponent implements OnInit {
  public blogPost: any = {
    title: '',
    text: '',
    image: '',
    video: '',
    date: ''
  };
  public upload: any = {
    blob: '',
    eventFiles: ''
  };
  public blogPosts: Array<any> = [];
  public displayedColumns: string[] = ['date', 'title', 'action'];
  public dataSource;
  public isExpanded: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('fileUploadInput') fileUploadInput: ElementRef;

  constructor(public app: AppService, public dialog: MatDialog, public data: DataService) { }

  ngOnInit() {
    this.data.getBlogPosts().subscribe(posts => {
      this.blogPosts = posts;
      this.dataSource = new MatTableDataSource<any>(this.blogPosts);
      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
    });
  }

  setExpandedState(e) {
    this.isExpanded = e;
  }

  async addPost() {
    let newId;

    if (!this.blogPost.id) {
      newId = this.app.generateUUID();
    }

    try {
      if (this.upload.eventFiles.length) {
        const ref = await this.startUpload(this.blogPost.id || newId);
        const refUrl = ref.toPromise();
        this.blogPost.image = await refUrl;
      }

      if (!this.blogPost.id) {
        this.blogPost.date = new Date();
        await this.app.afs.collection(`blog`).doc(newId).set(this.blogPost);
      } else {
        const postId = this.blogPost.id;
        delete this.blogPost.id;
        await this.app.afs.doc<any>(`blog/${postId}`).ref.update(this.blogPost);
      }
      this.clearPost();
      this.app.snackBar.open('Success!', 'x', {duration: 1200});
    } catch (err) {
      this.app.snackBar.open(err, 'x', {duration: 1000});
      console.log(err);
    }
  }

  editPost(post) {
    if (!this.isExpanded) {
      this.isExpanded = true;
    }
    this.blogPost = post;
    this.upload.blob = post.image;
  }

  deletePost(post) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '300px',
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        try {
          if (this.blogPost.image) {
            await this.app.afst.storage.ref(`blog/${post.id}`).delete();
          }
          await this.app.afs.doc<any>(`blog/${post.id}`).ref.delete();
          this.app.snackBar.open('Successfully deleted!', 'x', {duration: 1200});
        } catch (err) {
          this.app.snackBar.open(err, 'x', {duration: 1200});
        }
      }
    });
  }

  clearPost() {
    this.blogPost = {
      title: '',
      text: '',
      image: '',
      video: '',
      date: ''
    };

    this.clearUpload();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  deleteBlogImage() {
    this.clearUpload();
  }

  clearUpload() {
    this.upload = {
      blob: '',
      eventFiles: ''
    };

    this.fileUploadInput.nativeElement.value = '';
  }

  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      if (file.type.split('/')[0] !== 'image') {
        this.app.snackBar.open('Unsupported file type! Only image files are supported.', 'x', {duration: 1000});
        return;
      }

      reader.readAsDataURL(file);
      reader.onload = () => {
        this.upload.blob = reader.result;
        URL.createObjectURL(event.target.files[0]);
        this.upload.eventFiles = event.target.files;
      };
    }
  }

  async startUpload(id) {
    const file = this.upload.eventFiles[0];
    const path = `blog/${id}`;
    const fileRef = this.app.afst.ref(path);
    await this.app.afst.upload(path, file);
    return fileRef.getDownloadURL();
  }
}
