import { Component } from '@angular/core';
import {AppService} from './services/app/app.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'k3';


  constructor(private app: AppService, public translate: TranslateService) {
    this.app.language = window.location.hostname == 'k3-sportmanagement.com' ? 'en' : 'de';
    this.app.language = localStorage.getItem('locale') ? localStorage.getItem('locale') : this.app.language;
    translate.setDefaultLang(this.app.language);
    translate.use(this.app.language);
  }

  onRouterActivate(event) {
    const route = event.constructor.name;
    if (route !== 'LoginComponent' && route !== 'AdminComponent') {
      switch (route) {
        case 'HomepageComponent':
          this.app.selectedClientTab = 0;
          break;
        case 'AboutComponent':
          this.app.selectedClientTab = 1;
          break;
        case 'ClientsComponent':
          this.app.selectedClientTab = 2;
          break;
        case 'GalleryComponent':
          this.app.selectedClientTab = 3;
          break;
        case 'NewsComponent':
          this.app.selectedClientTab = 4;
          break;
        case 'HomeContactComponent':
          this.app.selectedClientTab = 5;
          break;
        case 'OurTeamComponent':
          this.app.selectedClientTab = 6;
          break;
      }
    }
  }
}
