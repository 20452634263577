import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterPlayer'
})
export class FilterPlayerPipe implements PipeTransform {

  transform(value: any, arg: any): any {
    return value.filter(val => val.type === arg);
  }

}
