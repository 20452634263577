import { Component, OnInit } from '@angular/core';
import {AppService} from '../../services/app/app.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public loginCredentials: any = {
    email: '',
    password: ''
  };

  constructor(private app: AppService) {
    if (app.afAuth.auth) {
      this.app.navigate('cms');
    }
  }

  ngOnInit() {
  }

  displaySnackMessage(message, action, duration) {
    this.app.snackBar.open(message, action, {duration});
  }

  async signIn() {
    try {
      await this.app.login(this.loginCredentials.email, this.loginCredentials.password);
      this.clearLoginCredentials();
      this.displaySnackMessage('Successfully logged in!', 'x', 800);
      this.app.navigate('cms');
    } catch (err) {
      this.displaySnackMessage('Invalid E-Mail or Password', 'x', 1200);
    }
  }

  clearLoginCredentials() {
    this.loginCredentials = {};
  }
}
