import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {DataService} from '../../services/data/data.service';

@Component({
  selector: 'app-gallery-container',
  templateUrl: './gallery-container.component.html',
  styleUrls: ['./gallery-container.component.css']
})
export class GalleryContainerComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public gallery, public data: DataService, public dialogRef: MatDialogRef<GalleryContainerComponent>) {}

  ngOnInit() {
  }

  closeGallery() {
    this.dialogRef.close();
  }
}
