import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../../services/app/app.service';
import {DataService} from '../../services/data/data.service';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';

interface  Client {
  id?: any;
  name: string;
  dob?: '';
  currentClub?: '';
  pob?: string;
  height?: string;
  nationality?: string;
  position?: string;
  positionType?: string;
  foot?: string;
  contract?: string;
  transferMarkt?: string;
  image?: string;
  video?: string;
  value?: number;
  age?: number;
}

@Component({
  selector: 'app-players',
  templateUrl: './players.component.html',
  styleUrls: ['./players.component.css']
})
export class PlayersComponent implements OnInit {
  public client: Client = {
    name: '',
    dob: '',
    currentClub: '',
    pob: '',
    height: '',
    nationality: '',
    position: '',
    positionType: '',
    foot: '',
    contract: '',
    transferMarkt: '',
    image: '',
    video: '',
    value: null
  };
  public clients: Array<any> = [];
  public displayedColumns: string[] = ['name', 'currentClub', 'nationality', 'contract', 'value', 'transfermarkt', 'action'];
  public dataSource;
  public isExpanded: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(public app: AppService, private data: DataService, public dialog: MatDialog) { }

  ngOnInit() {
    this.data.getClients().subscribe(clients => {
      this.clients = clients;
      this.dataSource = new MatTableDataSource<any>(this.clients);
      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
    });
  }

  setExpandedState(e) {
    this.isExpanded = e;
  }

  deleteClient(client) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '300px',
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        try {
          await this.app.afs.doc<any>(`clients/${client.id}`).ref.delete();
          this.app.snackBar.open('Successfully deleted!', 'x', {duration: 1200});
        } catch (err) {
          this.app.snackBar.open(err, 'x', {duration: 1200});
        }
      }
    });
  }

  async addClient() {
    try {
      if (!this.client.id) {
        this.client.age = this.app.getAge(this.client.dob);
        await this.app.afs.collection(`clients`).add(this.client);
      } else {
        await this.app.afs.doc<any>(`clients/${this.client.id}`).ref.update(this.client);
      }
      this.clearClient();
      this.app.snackBar.open('Success!', 'x', {duration: 1200});
    } catch (err) {
      this.app.snackBar.open(err, 'x', {duration: 1000});
      console.log(err);
    }
  }

  editClient(client) {
    if (!this.isExpanded) {
      this.isExpanded = true;
    }
    this.client = client;
  }

  clearClient() {
    this.client = {
      name: '',
      dob: '',
      currentClub: '',
      pob: '',
      height: '',
      nationality: '',
      position: '',
      positionType: '',
      foot: '',
      contract: '',
      transferMarkt: '',
      image: '',
      video: '',
      value: null
    };
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
