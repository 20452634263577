import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomepageComponent } from './frontend/homepage/homepage.component';
import { FilterPlayerPipe } from './pipes/filterPlayer/filter-player.pipe';
import { AboutComponent } from './frontend/about/about.component';
import { ClientsComponent } from './frontend/clients/clients.component';
import { NewsComponent } from './frontend/news/news.component';
import { GalleryComponent } from './frontend/gallery/gallery.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { ContactComponent } from './shared/contact/contact.component';
import { LoginComponent } from './admin/login/login.component';
import { AdminComponent } from './admin/admin.component';
import { PlayersComponent } from './admin/players/players.component';
import { AdminGalleryComponent } from './admin/admin-gallery/admin-gallery.component';
import { AdminBlogComponent } from './admin/admin-blog/admin-blog.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faMinusSquare, faStar} from '@fortawesome/free-regular-svg-icons';
import {
  MatButtonModule,
  MatDialogModule, MatExpansionModule,
  MatInputModule,
  MatPaginatorModule, MatProgressBarModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule
} from '@angular/material';
import {FormsModule} from '@angular/forms';
import {AngularFireModule} from '@angular/fire';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AngularFireStorageModule} from '@angular/fire/storage';
import { environment } from '../environments/environment';
import {faArrowLeft, faBars, faPenAlt, faSignOutAlt, faTimes, faUserMinus, faSortDown} from '@fortawesome/free-solid-svg-icons';
import { ConfirmDialogComponent } from './shared/confirm-dialog/confirm-dialog.component';
import { HomeContactComponent } from './frontend/home-contact/home-contact.component';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { PlayerModalComponent } from './shared/player-modal/player-modal.component';
import { GalleryContainerComponent } from './shared/gallery-container/gallery-container.component';
import { OurTeamComponent } from './frontend/our-team/our-team.component';
import {faFacebook, faInstagram, faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { AdminTeamComponent } from './admin/admin-team/admin-team.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

library.add(faMinusSquare, faSignOutAlt, faPenAlt, faUserMinus, faTimes, faInstagram, faFacebook, faStar, faWhatsapp, faBars, faArrowLeft, faSortDown);

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    FilterPlayerPipe,
    AboutComponent,
    ClientsComponent,
    NewsComponent,
    GalleryComponent,
    NavbarComponent,
    ContactComponent,
    LoginComponent,
    AdminComponent,
    PlayersComponent,
    AdminGalleryComponent,
    AdminBlogComponent,
    ConfirmDialogComponent,
    HomeContactComponent,
    PlayerModalComponent,
    GalleryContainerComponent,
    OurTeamComponent,
    AdminTeamComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    EditorModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    FormsModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatExpansionModule,
    MatProgressBarModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [ConfirmDialogComponent, PlayerModalComponent, GalleryContainerComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
