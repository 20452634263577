import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {AppService} from '../../services/app/app.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-player-modal',
  templateUrl: './player-modal.component.html',
  styleUrls: ['./player-modal.component.css']
})
export class PlayerModalComponent implements OnInit {
  public player;

  constructor(@Inject(MAT_DIALOG_DATA) public p: any, public app: AppService, private sanitizer: DomSanitizer, public dialogRef: MatDialogRef<PlayerModalComponent>) {
    this.player = {...this.p};
    if (this.player.video) {
      if (this.player.video.includes('youtube')) {
        this.player.video = this.player.video.replace('watch?v=', 'embed/');
      }

      this.player.video = sanitizer.bypassSecurityTrustResourceUrl(this.player.video);
    }
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }
}
