import { Component, OnInit } from '@angular/core';
import {DataService} from '../../services/data/data.service';
import {AppService} from '../../services/app/app.service';
import {MatDialog} from '@angular/material';
import {PlayerModalComponent} from '../../shared/player-modal/player-modal.component';
import {GalleryContainerComponent} from '../../shared/gallery-container/gallery-container.component';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {
  public filteredPlayers: Array<any> = [];
  public players: Array<any> = [];
  public news: Array<any> = [];
  public filterType: any = '';
  public gallery: any = {};

  constructor(public data: DataService, public app: AppService, public dialog: MatDialog) { }

  ngOnInit() {
    this.data.getClients(6).subscribe(clients => {
      this.players = clients;
      this.filteredPlayers = [...this.players];
    });

    this.data.getBlogPosts(3).subscribe(news => this.news = news);

    this.data.getGallery().subscribe(gallery => this.gallery = gallery[0]);
  }

  showPlayer(player) {
    this.dialog.open(PlayerModalComponent, {
      data: player, panelClass: 'client-modal'
    });
  }

  filterPlayers(param) {
    this.filterType = param;
    if (!param) {
      this.filteredPlayers = [...this.players];
    } else {
      this.filteredPlayers = this.players.filter(player => player.positionType == param);
    }
  }

  openGallery(imageIndex) {
    const data = {images: this.gallery.images, imageIndex};
    this.dialog.open(GalleryContainerComponent, {data, width: '100%', height: '100%', panelClass: 'dialog-transparent'});
  }
}
