import {Component, Input, OnInit} from '@angular/core';
import {DataService} from '../../services/data/data.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  @Input() showTitle: boolean = true;

  public contactForm: any = {
    name: '',
    email: '',
    subject: '',
    message: ''
  };

  constructor(private data: DataService) { }

  ngOnInit() {
  }

  async sendMessage() {
    try {
      await this.data.sendMessage(this.contactForm);
      this.clearContactForm();
    } catch (err) {
      console.log(err);
    }
  }

  clearContactForm() {
    this.contactForm = {
      name: '',
      email: '',
      subject: '',
      message: ''
    };
  }
}
