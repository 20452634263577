import { Component, OnInit } from '@angular/core';
import {DataService} from '../../services/data/data.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  public news: Array<any> = [];

  constructor(public data: DataService) { }

  ngOnInit() {
    this.data.getBlogPosts().subscribe(news => this.news = news);
  }

}
