import { Component, OnInit } from '@angular/core';
import {AppService} from '../services/app/app.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  constructor(public app: AppService) { }

  ngOnInit() {
  }

  changeTab(route) {
    this.app.childNavigate('cms', 'cms', route);
  }

  onRouterActivate(event) {
    const route = event.constructor.name;
    switch (route) {
      case 'PlayersComponent':
        this.app.selectedAdminTab = 0;
        break;
      case 'AdminBlogComponent':
        this.app.selectedAdminTab = 1;
        break;
      case 'AdminGalleryComponent':
        this.app.selectedAdminTab = 2;
        break;
      case 'AdminTeamComponent':
        this.app.selectedAdminTab = 3;
        break;
    }
  }
}
