import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AppService} from '../../services/app/app.service';
import {DataService} from '../../services/data/data.service';
import {MatDialog, MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {ConfirmDialogComponent} from '../../shared/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-admin-team',
  templateUrl: './admin-team.component.html',
  styleUrls: ['./admin-team.component.css']
})
export class AdminTeamComponent implements OnInit {
  public teamMember: any = {
    date: '',
    name: '',
    position: '',
    image: '',
    titles: [],
    dob: '',
    text: ''
  };

  public upload: any = {
    blob: '',
    eventFiles: ''
  };
  public teamMembers: Array<any> = [];
  public displayedColumns: string[] = ['date', 'position', 'name', 'action'];
  public dataSource;
  public isExpanded: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('fileUploadInput') fileUploadInput: ElementRef;

  constructor(public app: AppService, public dialog: MatDialog, public data: DataService) { }

  ngOnInit() {
    this.data.getTeam().subscribe(team => {
      this.teamMembers = team;
      this.dataSource = new MatTableDataSource<any>(this.teamMembers);
      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
    });
  }

  setExpandedState(e) {
    this.isExpanded = e;
  }

  async addMember() {
    let newId;

    if (!this.teamMember.id) {
      newId = this.app.generateUUID();
    }

    try {
      if (this.upload.eventFiles.length) {
        const ref = await this.startUpload(this.teamMember.id || newId);
        const refUrl = ref.toPromise();
        this.teamMember.image = await refUrl;
      }

      this.teamMember.date = new Date();

      if (!this.teamMember.id) {
        this.teamMember.date = new Date();
        await this.app.afs.collection(`team`).doc(newId).set(this.teamMember);
      } else {
        const postId = this.teamMember.id;
        delete this.teamMember.id;
        await this.app.afs.doc<any>(`team/${postId}`).ref.update(this.teamMember);
      }
      this.clearMember();
      this.app.snackBar.open('Success!', 'x', {duration: 1200});
    } catch (err) {
      this.app.snackBar.open(err, 'x', {duration: 1000});
      console.log(err);
    }
  }

  addTitleField() {
    this.teamMember.titles.push('');
  }

  editMember(member) {
    if (!this.isExpanded) {
      this.isExpanded = true;
    }
    this.teamMember = member;
    this.upload.blob = member.image;
  }

  deleteMember(member) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '300px',
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        try {
          if (this.teamMember.image) {
            await this.app.afst.storage.ref(`team/${member.id}`).delete();
          }
          await this.app.afs.doc<any>(`team/${member.id}`).ref.delete();
          this.app.snackBar.open('Successfully deleted!', 'x', {duration: 1200});
        } catch (err) {
          this.app.snackBar.open(err, 'x', {duration: 1200});
        }
      }
    });
  }

  clearMember() {
    this.teamMember = {
      date: '',
      name: '',
      position: '',
      image: '',
      titles: [],
      dob: '',
      text: ''
    };

    this.clearUpload();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  deleteMemberImage() {
    this.clearUpload();
  }

  clearUpload() {
    this.upload = {
      blob: '',
      eventFiles: ''
    };

    this.fileUploadInput.nativeElement.value = '';
  }

  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      if (file.type.split('/')[0] !== 'image') {
        this.app.snackBar.open('Unsupported file type! Only image files are supported.', 'x', {duration: 1000});
        return;
      }

      reader.readAsDataURL(file);
      reader.onload = () => {
        this.upload.blob = reader.result;
        URL.createObjectURL(event.target.files[0]);
        this.upload.eventFiles = event.target.files;
      };
    }
  }

  async startUpload(id) {
    const file = this.upload.eventFiles[0];
    const path = `team/${id}`;
    const fileRef = this.app.afst.ref(path);
    await this.app.afst.upload(path, file);
    return fileRef.getDownloadURL();
  }
}
