import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {AppService} from '../../../services/app/app.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private app: AppService) {}

  canActivate(): Observable<boolean> {
    return this.app.afAuth.authState.pipe(map(auth => {
      if (!auth) {
        this.app.navigate('login');
      }
      return !!auth;
    }));
  }
}
