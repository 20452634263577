import { Component, OnInit } from '@angular/core';
import {DataService} from '../../services/data/data.service';
import {AppService} from '../../services/app/app.service';

@Component({
  selector: 'app-our-team',
  templateUrl: './our-team.component.html',
  styleUrls: ['./our-team.component.css']
})
export class OurTeamComponent implements OnInit {
  public displayMemberInfo: boolean = false;
  public selectedMember: any = {};
  public teamMembers: Array<any> = [];

  constructor(public data: DataService, public app: AppService) {
    this.data.getTeam().subscribe(teams => this.teamMembers = teams);
  }

  ngOnInit() {
  }

  clearSelectedMember() {
    this.selectedMember = {};
  }

  hideMember() {
    this.displayMemberInfo = false;
    this.clearSelectedMember();
  }

  showMember(member) {
    this.selectedMember = member;
    this.displayMemberInfo = true;
    window.scrollTo(0, 0);
  }
}
