import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomepageComponent} from './frontend/homepage/homepage.component';
import {AboutComponent} from './frontend/about/about.component';
import {ClientsComponent} from './frontend/clients/clients.component';
import {NewsComponent} from './frontend/news/news.component';
import {GalleryComponent} from './frontend/gallery/gallery.component';
import {LoginComponent} from './admin/login/login.component';
import {AdminComponent} from './admin/admin.component';
import {PlayersComponent} from './admin/players/players.component';
import {AdminBlogComponent} from './admin/admin-blog/admin-blog.component';
import {AdminGalleryComponent} from './admin/admin-gallery/admin-gallery.component';
import {AuthGuard} from './admin/guards/auth/auth.guard';
import {HomeContactComponent} from './frontend/home-contact/home-contact.component';
import {OurTeamComponent} from './frontend/our-team/our-team.component';
import {AdminTeamComponent} from './admin/admin-team/admin-team.component';

const routes: Routes = [
  { path: 'home', component: HomepageComponent },
  { path: 'about', component: AboutComponent },
  { path: 'clients', component: ClientsComponent },
  { path: 'news', component: NewsComponent },
  { path: 'gallery', component: GalleryComponent },
  { path: 'contact', component: HomeContactComponent },
  { path: 'team', component: OurTeamComponent },
  { path: 'cms', component: AdminComponent, canActivate: [AuthGuard], children: [
    { path: 'players', component: PlayersComponent, outlet: 'cms' },
    { path: 'blog', component: AdminBlogComponent, outlet: 'cms' },
    { path: 'gallery', component: AdminGalleryComponent, outlet: 'cms' },
    { path: 'team', component: AdminTeamComponent, outlet: 'cms' },
    { path: '', component: PlayersComponent, outlet: 'cms' }
  ]},
  { path: 'login', component: LoginComponent },
  { path: '', component: HomepageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
