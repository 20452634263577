import { Component, OnInit } from '@angular/core';
import {DataService} from '../../services/data/data.service';
import {AppService} from '../../services/app/app.service';
import {PlayerModalComponent} from '../../shared/player-modal/player-modal.component';
import {MatDialog} from '@angular/material';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {
  public players: Array<any> = [];
  public filteredPlayers: Array<any> = [];
  public filterType: any = '';
  public selectedPlayer: any = {};
  public displaySelectedPlayer: boolean = false;

  constructor(public data: DataService, public app: AppService, public dialog: MatDialog, private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.data.getClients().subscribe(clients => {
      this.players = clients;
      this.filteredPlayers = [...this.players];

      this.filteredPlayers.map(player => {
        if (player.video) {
          if (player.video.includes('youtube')) {
            player.video = player.video.replace('watch?v=', 'embed/');
          }

          player.video = this.sanitizer.bypassSecurityTrustResourceUrl(player.video);
        }
      });
    });
  }

  showPlayer(player) {
    this.displaySelectedPlayer = true;
    window.scrollTo(0, 0);
    this.selectedPlayer = player;
  }

  hidePlayer() {
    this.displaySelectedPlayer = false;
    this.clearSelectedPlayer();
  }

  clearSelectedPlayer() {
    this.selectedPlayer = {};
  }

  filterPlayers(param) {
    this.filterType = param;
    if (!param) {
      this.filteredPlayers = [...this.players];
    } else {
      this.filteredPlayers = this.players.filter(player => player.positionType == param).sort((a, b) => b.value - a.value);
    }
  }
}
