import {Component, OnInit} from '@angular/core';
import {AppService} from '../../services/app/app.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  public displayMenuOverlay: boolean = false;
  public displayLanguageDropdown: boolean = false;

  constructor(public app: AppService) { }

  ngOnInit() {
  }

  showMenuOverlay() {
    this.displayMenuOverlay = true;
  }

  hideMenuOverlay() {
    this.displayMenuOverlay = false;
  }

  toggleMenuOverlay() {
    this.displayMenuOverlay = !this.displayMenuOverlay;
  }

  toggleLanguageDropdown() {
    this.displayLanguageDropdown = !this.displayLanguageDropdown;
  }

  hideLanguageDropdown(event) {
    if (this.displayLanguageDropdown && (!event.path[1].className.includes('a1') && !event.path[2].className.includes('a1'))) {
      this.displayLanguageDropdown = false;
    }
  }

  setLanguage(code) {
    this.displayLanguageDropdown = false;
    this.app.setLanguage(code);
    if (this.displayMenuOverlay) {
      this.displayMenuOverlay = false;
    }
  }
}
