import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore} from '@angular/fire/firestore';
import {MatSnackBar} from '@angular/material';
import {AngularFireStorage} from '@angular/fire/storage';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public selectedAdminTab: number = 0;
  public selectedClientTab: number = 0;
  public language: string = 'en';

  constructor(private router: Router, public afAuth: AngularFireAuth, public afs: AngularFirestore, public afst: AngularFireStorage, public snackBar: MatSnackBar, private translate: TranslateService) { }

  navigate(route) {
    this.router.navigate([route]);
  }

  childNavigate(path, outlet, route) {
    this.router.navigate([`/${path}`, { outlets: { [`${outlet}`]: [route] } }]);
  }

  login(username, password) {
    return this.afAuth.auth.signInWithEmailAndPassword(username, password);
  }

  async logout() {
    try {
      await this.afAuth.auth.signOut();
      this.navigate('');
    } catch (err) {
      console.log(err);
    }
  }

  generateUUID() {
    let d = new Date().getTime();
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      let r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
  }

  stripHTML(html) {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }

  getAge(d) {
    const dateSplitted = d.split('.');
    const date = `${dateSplitted[3]}-${dateSplitted[2]}-${dateSplitted[1]}`;
    return Math.floor((+new Date() - new Date(date).getTime()) / 3.15576e+10);
  }

  getStringCurrency(value) {
    return Math.abs(Number(value)) >= 1.0e+9
      ? Math.abs(Number(value)) / 1.0e+9 + 'b'
      : Math.abs(Number(value)) >= 1.0e+6
        ? Math.abs(Number(value)) / 1.0e+6 + 'm'
        : Math.abs(Number(value)) >= 1.0e+3
          ? Math.abs(Number(value)) / 1.0e+3 + 'k'
          : Math.abs(Number(value));
  }

  setLanguage(code) {
    this.language = code;
    localStorage.setItem('locale', code);
    this.translate.setDefaultLang(this.language);
    this.translate.use(this.language);
  }
}
