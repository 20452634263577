import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { DataService } from '../../services/data/data.service';
import { AppService } from '../../services/app/app.service';
import {ConfirmDialogComponent} from '../../shared/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material';
import {GalleryContainerComponent} from '../../shared/gallery-container/gallery-container.component';

@Component({
  selector: 'app-admin-gallery',
  templateUrl: './admin-gallery.component.html',
  styleUrls: ['./admin-gallery.component.css']
})
export class AdminGalleryComponent implements OnInit {
  public isDragOver: boolean = false;
  public isUploading: boolean = false;
  public gallery: any = {};
  public toUploadImages: Array<any> = [];
  public toUploadFiles: Array<any> = [];
  @ViewChild('fileUploadInput') fileUploadInput: ElementRef;

  constructor(public data: DataService, public app: AppService, private dialog: MatDialog) { }

  ngOnInit() {
    // TODO: Refactor
    this.data.getGallery().subscribe(data => this.gallery = data[0]);
  }

  openGallery(imageIndex) {
    const data = {images: this.gallery.images, imageIndex};
    this.dialog.open(GalleryContainerComponent, {data, width: '100%', height: '100%', panelClass: 'dialog-transparent'});
  }

  onDrop(event) {
    event.stopPropagation();
    event.preventDefault();
    this.isDragOver = false;
    this.onFileDrop(event);
  }

  onDragOver(event) {
    event.stopPropagation();
    event.preventDefault();
    this.isDragOver = true;
  }

  onDragLeave(event) {
    this.isDragOver = false;
  }

  onFileDrop(event) {
    this.toUploadFiles = event.target.files || event.dataTransfer.files;

    if (this.toUploadFiles.length) {

      for (let i = 0; i < this.toUploadFiles.length; i++) {
        const reader = new FileReader();
        reader.readAsDataURL(this.toUploadFiles[i]);
        reader.onload = () => {
          this.toUploadImages.push({url: reader.result});
          URL.createObjectURL(this.toUploadFiles[i]);
        };
      }
    }
  }

  resetFileInput() {
    this.fileUploadInput.nativeElement.value = '';
  }

  clearUpload() {
    this.toUploadFiles = [];
    this.toUploadImages = [];
    this.resetFileInput();
  }

  async upload() {
    let images = [];

    for (let i = 0; i < this.toUploadFiles.length; i++) {
      const path = `gallery/${this.toUploadFiles[i].name}`;
      const fileRef = this.app.afst.ref(path);
      try {
        await this.app.afst.upload(path, this.toUploadFiles[i]);
        const ref = fileRef.getDownloadURL().toPromise();
        const dUrl = await ref;
        images.push({fileName: this.toUploadFiles[i].name, url: dUrl, thumbnailUrl: dUrl});
      } catch (err) {
        this.app.snackBar.open(err, 'x', {duration: 1200});
      }
    }
    return images;
  }

  async save() {
    this.isUploading = true;
    try {
      const images = await this.upload();
      images.forEach(image => this.gallery.images.push(image));
      await this.app.afs.doc<any>('gallery/gavjjh5NFdvx1V9FDClR').ref.update(this.gallery);
      this.clearUpload();
      this.app.snackBar.open('Successful upload!', 'x', {duration: 1000});
    } catch (err) {
      this.app.snackBar.open(err, 'x', {duration: 1200});
    } finally {
      this.isUploading = false;
    }
  }

  deleteUploadImage(i) {
    // Array.prototype.splice.call(this.toUploadFiles, i, 1);
    this.toUploadImages.splice(i, 1);
  }

  async deleteImage(i) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '300px',
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        try {
          await this.app.afst.storage.ref(`gallery/${this.gallery.images[i].fileName}`).delete();
          this.gallery.images.splice(i, 1);
          await this.app.afs.doc<any>('gallery/gavjjh5NFdvx1V9FDClR').ref.update(this.gallery);
          this.app.snackBar.open('Success!', 'x', {duration: 1200});
        } catch (err) {
          this.app.snackBar.open(err, 'x', {duration: 1200});
        }
      }
    });
  }
}
