import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators';
import {AppService} from '../app/app.service';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public editorKey: string = '2dx744u4k1i822lu7g3jkidfjwjgbdi8olghqkov2pywbhr2';
  constructor(public app: AppService, private http: HttpClient) { }

  getClients(limit?) {
    return this.app.afs
      .collection('clients', ref => ref.limit(limit || 1000).orderBy('value', 'desc'))
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
  }

  getBlogPosts(limit?) {
    return this.app.afs
      .collection('blog', ref =>
        ref.orderBy('date', 'desc').limit(limit || 1000)
      )
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
  }

  getGallery(limit?) {
    return this.app.afs
      .collection('gallery', ref => ref.limit(limit || 1000))
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
  }

  getTeam() {
    return this.app.afs
      .collection('team')
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
  }

  async sendMessage(messageObject) {
    const url = 'https://us-central1-k3sm-696f4.cloudfunctions.net/sendMail';
    // return translation based on user language
    try {
      const req = this.http.post(url, JSON.stringify(messageObject)).toPromise();
      const res = await req;
      this.app.snackBar.open('Thank you for contacting us, we will get back to you soon!', 'x', {duration: 1200});
    } catch (err) {
      this.app.snackBar.open(JSON.stringify(err), 'x', {duration: 1200});
    }
  }
}
